.ContestPresentation {
  header {
    background-image: url("../../images/banner_1.jpg");
  }

  .specifications {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .reward,
    .winners {
      font-size: 1.2em;
      padding-top: 10px;
    }
    .reward {
      padding-right: 20px;
      padding-left: 10px;
    }
    .winners {
      padding-right: 10px;
      padding-left: 20px;
    }
    .reward span,
    .winners span {
      background-color: #5bc0f5;
      color: white;
      padding-left: 5px;
      padding-right: 5px;
      padding-top: 1px;
      padding-bottom: 1px;
    }
    .TimeCounter {
      min-width: 250px;
      width: auto;
      font-size: 2em;
    }
  }

  .specifications.small {
    flex-direction: column;
    justify-content: space-evenly;
    .TimeCounter {
      width: 100%;
      margin: auto;
    }
    .winnerAndReward {
      width: 100%;
      margin: auto;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 20px;
      .reward,
      .winners {
        width: 45%;
        padding: 2%;
        font-size: 0.9em;
      }
      .reward span,
      .winners span {
        width: 100%;
        height: 100%;
        display: block;
        padding: 0px;
        margin: 0px;
        text-align: center;
      }
    }
  }

  .timeWrapper {
    .lineUp {
      height: 0px;
      width: 100%;
      text-align: center;
      border-top: 2px solid black;
      margin-top: 70px;

      span {
        background-color: white;
        position: relative;
        bottom: 10px;
        padding-left: 10px;
        padding-right: 10px;
        text-transform: uppercase;
        font-size: 0.8em;
        font-weight: bold;
      }
    }

    .lineDown {
      fill: black;
      stroke: black;
      stroke-width: 2px;
      width: 100%;
      height: 20px;
      margin-top: 5px;
    }
  }

  .subscribe {
    text-align: center;
    margin-bottom: 30px;
    height: 60px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 30px;
    margin-top: 20px;

    .vAlign {
      label {
        height: 20px;
        font-size: 0.9em;
        text-align: left;
        padding-left: 2px;
      }
      input {
        width: 100%;
        height: 30px;
        margin: 0px;
        border-radius: 4px;
        color: #5bc0f5;
        text-align: left;
        border: 1px solid #5bc0f5;
        font-weight: bold;
        box-sizing: border-box;
        text-indent: 10px;
      }
      input::placeholder {
        color: rgba(0, 0, 0, 0.3);
        text-indent: 10px;
      }
    }

    .vAlign:nth-child(2) {
      width: 40%;
    }

    .loginEmailButton {
      width: 25%;
      background-color: #5bc0f5;
      border: 2px solid #5bc0f5;
      border-radius: 4px;
      color: white;
      border: none;
      font-weight: 700;
      -webkit-font-smoothing: antialiased;
      margin-left: 10px;
      margin-top: 26px;
      height: 30px;
      font-size: 0.8em;
      cursor: pointer;
    }
  }

  @media only screen and (max-width: 500px) {
    font-size: 0.9em;

    .subscribe {
      flex-direction: column;
      height: 200px;

      .vAlign {
        height: 70px;
        input {
          width: 100%;
          height: 40px;
          padding: 0px;
          margin: 0px;
          margin: auto;
          font-size: 16px;
          padding: 0px 0px;
          margin-bottom: 20px;
        }
      }
      .vAlign:nth-child(2) {
        width: 100%;
      }
      .loginEmailButton {
        width: 100%;
        margin: 0px;
        margin: auto;
        font-size: 16px;
        padding: 0px;
      }
    }
  }
}
