.CopyLink {
  padding: 0px;
  border-radius: 4px;
  overflow: hidden;
  white-space: nowrap;
  resize: none;

  .copyButton {
    width: 100%;
    white-space: normal;
    color: #3c4554;
    font-size: 1em;
    font-weight: 300;
    text-align: left;
    padding: 0px;
    margin: 0px;
    background-color: transparent;
    border: none;
    font-family: "Poppins", "Helvetica";
    cursor: pointer;
  }

  .closeButton {
    width: 100%;
    overflow: hidden;
    background: transparent;
    border: none;
    font-weight: 600;
    font-family: "Poppins";
    font-size: 0.8em;
    text-align: center;
    box-sizing: border-box;
    cursor: pointer;
    color: #3c4554;
    font-family: "Poppins", "Helvetica";
  }

  .linkOverlaySaver {
    position: fixed;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    .wrapper {
      background-color: rgba(255, 255, 255, 0.85);
      max-width: 500px;
      height: 120px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      border-radius: 6px;
      position: relative;
      top: 50%;
      transform: translateY(-50%);
      border: 2px solid #5bc0f5;
      margin: auto;
      .closeButton {
        text-align: center;
        font-family: "Poppins", "Helvetica";
        font-size: 16px;
        color: #3c4554;
      }
    }
  }

  .linkText {
    border: none;
    font-weight: 600;
    font-size: 0.8em;
    text-align: center;
    margin: auto;
    font-size: 16px;
    width: 100%;
    resize: none;
    display: block;
    margin: 0px;
    color: #5bc0f5;
    font-family: "Poppins", "Helvetica";
    border: none;
    white-space: normal;
    overflow-wrap: break-word;
    white-space: normal;
  }

  .linkOverlaySaver.inactive {
    visibility: hidden;
  }
}
