.Ranking {
  p {
    font-weight: 300;
    width: 98%;
    margin: auto;
    margin-top: 10px;
    margin-bottom: 20px;
  }

  table {
    width: 400px;
    min-height: 400px;
    border: 2px solid #5bc0f5;
    padding: 10px;
    border-radius: 10px;
    min-width: 50%;
    margin: auto;
    td {
      text-align: center;
    }
    td:first-child {
      width: 70px;
    }
    td:nth-child(2) {
      font-weight: bold;
    }
    td:last-child {
      width: 90px;
    }
    tr.isUser {
      background-color: #5bc0f5;
      color: white;
    }
  }

  @media only screen and (max-width: 400px) {
    table {
      width: 95%;
    }
  }
}
