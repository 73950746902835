.Alert {
	position: fixed;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	visibility: visible;
	z-index: 111;

	.wrapper {
		margin: auto;
		max-width: 600px;
		background-color: rgba(255, 255, 255, 0.9);
		border-radius: 5px;
		box-shadow: 2px 2px 30px rgba(0, 0, 0, 0.2);
		padding: 20px;
		text-align: center;
		position: relative;
		z-index: 111;
		p {
			text-align: center;
			font-size: 1em;
			font-weight: 600;
		}
		button {
			padding-left: 20px;
			padding-right: 20px;
			background-color: #e67972;
			border: 2px solid #e67972;
			border-radius: 4px;
			color: white;
			font-weight: 700;
			height: 30px;
			font-size: 0.8em;
			cursor: pointer;
		}
	}
}
.Alert.inactive {
	visibility: hidden;
}
