.Actions {
  margin-bottom: 30px;
  position: relative;

  table {
    list-style-type: none;
    margin: 0;
    padding: 0;
    width: 90%;
    margin: auto;
    tbody {
      width: 100%;
    }
  }

  tr {
    font-family: "Poppins", "Helvetica";
    border-bottom: 2px solid rgba(0, 0, 0, 0.04);
    padding-top: 5px;
    padding-bottom: 5px;
    cursor: pointer;
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .logo {
      width: 40px;
      height: 40px;
      background-repeat: no-repeat;
      background-position: center;
    }

    @keyframes checkmark {
      0% {
        height: 0%;
        width: 0%;
        opacity: 0;
      }
      100% {
        height: 50%;
        width: 25%;
        opacity: 1;
      }
    }

    @keyframes foregroundCheckmark {
      0% {
        color: rgb(77, 77, 77);
        font-weight: 500;
      }
      100% {
        color: white;
      }
    }

    @keyframes backgroundCheckmark {
      0% {
        background-color: rgba(0, 0, 0, 0.1);
      }
      100% {
        background-color: #5bc0f5;
      }
    }

    .customCheckbox {
      width: 30px;
      min-width: 30px;
      height: 30px;
      border: none;
      padding: 0px;
      margin: 0px;
      background-color: rgba(0, 0, 0, 0.1);
      color: white;
      font-size: 30px;
      line-height: 30px;
      visibility: visible;
      border-radius: 3px;
      margin-top: 5px;
    }

    .customCheckbox.active {
      animation-name: backgroundCheckmark;
      animation-timing-function: ease-in-out;
      animation-duration: 0.5s;
      background-color: #5bc0f5;
    }

    .customCheckbox .check {
      display: inline-block;
      transform: rotate(45deg);
      height: 0%;
      width: 0%;
      margin-left: 25%;
      margin-bottom: 10%;
      border-bottom: 5px solid white;
      border-right: 5px solid white;
      opacity: 0;
    }

    .customCheckbox.active .check {
      animation-name: checkmark;
      animation-timing-function: ease-in-out;
      animation-duration: 0.5s;
      height: 50%;
      width: 25%;
      opacity: 1;
    }

    .reward {
      width: fit-content;
      padding-left: 10px;
      padding-right: 15px;
      border-radius: 4px;
      background-color: rgba(0, 0, 0, 0.1);
      display: flex;
      justify-content: space-evenly;
      color: rgb(77, 77, 77);
      height: 40px;
      .immediatReward {
        line-height: 40px;
        height: 40px;
        display: block;
        font-size: 1.3em;
      }
      .referralReward {
        width: 45px;
        line-height: 1.1em;
        font-size: 0.75em;
        padding-left: 0px;
        margin-top: 5px;
        text-align: right;
        .filleul {
          font-size: 1.07em;
          letter-spacing: 0.5px;
        }
      }
    }

    @media only screen and (max-width: 500px) {
      .reward .referralReward {
        line-height: 1.3em;
      }
    }

    .reward.active {
      background-color: #5bc0f5;
      .immediatReward {
        color: white;
      }
      .referralReward {
        color: white;
      }
    }

    .description {
      text-decoration: none;
      margin: 0px;
      height: 40px;
      background: none;
      border: none;
      text-align: left;
      color: #3c4554;
      font-size: 1em;
      line-height: 40px;
      padding: 0px;
      padding-left: 5px;
      padding-top: 2px;
      display: flex;
      align-items: center;
      letter-spacing: 0.5px;
      font-weight: 300;
      width: 100%;
    }

    @media only screen and (max-width: 500px) {
      .description {
        font-size: 0.8em;
        line-height: initial;
      }
    }
    .description.performed .CopyLink button,
    .description.active,
    .description.active button {
      color: #5bc0f5;
      font-weight: 600;
    }
  }

  tr:last-child {
    border: none;
  }

  tr {
    td {
      width: calc(100% - 40px);
    }
    td:first-child {
      width: 40px;
    }
  }

  .logo.whatsapp_share {
    background-image: url("../../images/social/whatsapp.png");
    background-size: 25px 25px;
  }
  .logo.twitter_share {
    background-image: url("../../images/social/twitter.png");
    background-size: 25px 25px;
  }
  .logo.instagram_follow {
    background-image: url("../../images/social/instagram.png");
    background-size: 25px 25px;
  }

  .logo.messenger_share {
    background-image: url("../../images/social/messenger.png");
    background-size: 25px 25px;
  }
  .logo.facebook_like {
    background-image: url("../../images/social/fb_like.png");
    background-size: 25px 25px;
  }
  .logo.linkedin_share,
  .logo.linkedin_like {
    background-image: url("../../images/social/linkedin.png");
    background-size: 25px 25px;
  }

  .logo.facebook_share,
  .logo.fb_watch_share {
    background-image: url("../../images/social/facebook.png");
    background-size: 25px 25px;
  }

  .logo.sms_share {
    background-image: url("../../images/social/sms.png");
    background-size: 25px 25px;
  }

  .logo.email_share {
    background-image: url("../../images/social/email.png");
    background-size: 25px 25px;
  }

  .logo.youtube_follow {
    background-image: url("../../images/social/youtube.png");
    background-size: 25px 25px;
  }

  .hiddenOverlay {
    position: absolute;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.75);
    border-radius: 5px;
    border: 2px solid #e77972;
    box-sizing: border-box;
    width: 100%;
    div {
      margin: 0;
      position: absolute;
      top: 50%;
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      text-align: center;
      font-weight: 500;
      width: 100%;
    }
  }
}
