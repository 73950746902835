body {
  margin: 0;
  color: #3c4453;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(250, 250, 250);
}

:focus {
  outline: none;
}

.verticalCenter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding: 0px;
}

.sponsor {
  width: 100%;
  text-align: center;
  a {
    color: rgb(180, 180, 180);
    text-decoration: none;
    font-size: 0.95em;
  }
  margin-bottom: 20px;
}
