.Main {
  max-width: 650px;
  background-color: white;
  box-shadow: 0 10px 35px 0 rgba(99, 123, 173, 0.2);
  margin: auto;
  border-radius: 10px;
  overflow: hidden;
  margin-top: 20px;
  margin-bottom: 20px;
  border: 4px solid white;

  h1 {
    position: relative;
    top: 270px;
    height: 50px;
    line-height: 50px;
    background-color: white;
    border-radius: 8px;
    width: 90%;
    margin: auto;
    color: #3c4453;
    font-size: 1.7rem;
    font-weight: 500;
    z-index: 0;
    @media only screen and (max-width: 400px) {
      top: 100px;
      font-size: 1.3em;
      height: 40px;
      line-height: 40px;
    }
  }

  h2 {
    font-size: 1.1em;
    line-height: 35px;
    font-weight: 600;
    text-align: center;
    padding-left: 10px;
    margin: 10px;
  }

  p {
    text-align: center;
    line-height: 30px;
    font-weight: 100;
    margin-bottom: 15px;
    margin-top: 15px;
  }

  strong {
    font-weight: 600;
  }

  a {
    color: #5bc0f5;
  }

  .description {
    padding-left: 30px;
    padding-right: 30px;
    text-align: center;
    line-height: 25px;
    font-weight: 300;
    font-size: 1em;
  }

  header {
    margin: 0px;
    height: 260px;
    object-fit: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: auto 100%;
    text-align: center;
    margin-bottom: 90px;
  }

  @media only screen and (max-width: 400px) {
    header {
      background-size: 100%;
      height: 120px;
    }
  }

  footer {
    margin: 0px;
    width: 100%;
    height: 200px;
    box-sizing: border-box;
    position: relative;
    background: url("../images/footer.png");
    background-size: 100%, auto;
    background-position: center bottom;
    background-repeat: no-repeat;
  }

  @media only screen and (max-width: 500px) {
    footer {
      height: 150px;
    }
  }

  .vAlign {
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
  }
}

.Main.noScroll {
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
}
