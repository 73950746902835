.TimeCounter {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: auto;
  height: 60px;
  text-align: center;
  font-family: Roboto Mono, Roboto, sans-serif;
  line-height: 1.3rem;
  font-size: 2.2rem;
  font-weight: 300;
  text-align: center;

  .delay_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    .label {
      font-size: 0.4em;
    }
  }

  .delimiter {
    font-family: Roboto Mono, Roboto, sans-serif;
    margin-top: 5px;
  }
}
