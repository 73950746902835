.ContestValidation {
  header {
    margin-bottom: 60px;
    background-image: url("../../images/banner_2.jpg");
  }

  h2 {
    margin-top: 60px;
  }

  .Referrals {
    margin-top: 10px;
    margin-bottom: 30px;
  }

  .thanks {
    text-align: center;
    margin-top: 60px;
    font-weight: 400;
    margin-bottom: 60px;
  }

  .description {
    margin-top: 10px;
    margin-bottom: 30px;
  }

  .actionBonus {
    margin-top: 0px;
    margin-bottom: 10px;
  }

  .conclusion {
    margin-top: 100px;
  }

  .actionBonusTitle {
    margin-bottom: 0px;
  }

  .bonusAction tr .reward.inactive {
    background-color: #5bc0f5;
    color: white;
  }

  .supportUs {
    font-size: 1.1em;
    display: flex;
    justify-content: space-evenly;
    width: 90%;
    margin: auto;
    height: 30px;
    margin-top: 40px;
    margin-bottom: 40px;

    .currentReward {
      background-color: #5bc0f5;
      color: white;
      border-radius: 2px;
      line-height: 30px;
      padding-left: 10px;
      padding-right: 10px;
    }
    .text {
      line-height: 30px;
    }
  }

  .redText {
    color: #5bc0f5;
  }

  .shareLink {
    border: 2px solid #5bc0f5;
    width: 90%;
    margin: auto;
    margin-bottom: 30px;
    .copyButton {
      color: #5bc0f5;
      text-align: center;
      font-weight: 500;
    }
  }

  .Ranking {
    margin-bottom: 40px;
  }

  .boldUnderlined {
    font-weight: bold;
    text-decoration: underline;
  }

  @media only screen and (max-width: 500px) {
    font-size: 0.9em;
    .thanks {
      margin-bottom: 0px;
    }
  }
}
